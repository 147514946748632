import React from 'react';
import * as s from './styles';


const Footnotes = ({ items, large, className }) => {
  return (
    <s.FootnoteContainer className={className}>

      {items.map((item, i) => (
        <s.FootnoteItem key={i} large={large} marginBottom={item.marginBottom}>
          {item.ref &&
            <s.FootnoteRef>{item.ref}</s.FootnoteRef>
          }
          <s.FootnoteCopy dangerouslySetInnerHTML={{__html: item.copy}} />
        </s.FootnoteItem>
      ))}

    </s.FootnoteContainer>
  );
};


export default Footnotes;