const guidelinesCopy = {
  pageTitle: 'Guidelines for WELIREG™ (belzutifan)',
  topHeader: 'Belzutifan (WELIREG) is included in certain NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)<sup>1,2</sup>',

  iconCards: [
    {
      title: 'NCCN Guidelines® for Kidney Cancer (confirmed hereditary VHL disease&ndash;associated RCC)',
      copy: 'Belzutifan (WELIREG) is the <strong>only preferred systemic therapy option (category 2A)</strong> for patients with VHL disease&ndash;associated RCC not requiring immediate surgery.<sup>1</sup>',
      alt: 'NCCN Guidelines® for Kidney Cancer (confirmed hereditary VHL-associated RCC)',
    },
    {
      title: 'NCCN Guidelines for CNS Cancers <span class="no-wrap">(VHL disease&ndash;associated</span> CNS hemangioblastomas)',
      copy: 'Belzutifan (WELIREG) is the <strong>only systemic therapy option identified as useful in certain circumstances (category 2A)</strong> for patients with VHL disease&ndash;associated CNS hemangioblastomas not requiring immediate surgery.<sup>2</sup>',
      alt: 'NCCN Guidelines for CNS Cancers <span class="no-wrap">(VHL-associated</span> CNS hemangioblastomas)',
    },
  ],

  footnotes: [
    {
      copy: 'Category 2A = Based upon lower-level evidence, there is uniform National Comprehensive Cancer Network® (NCCN®) consensus that the intervention is appropriate. ',
    },
    {
      copy: 'NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.',
    },
    {
      copy: 'NCCN = National Comprehensive Cancer Network; VHL = von Hippel-Lindau; RCC = renal cell carcinoma; CNS = central nervous system.',
    },
  ],

  extraFootnote: 'NCCN = National Comprehensive Cancer Network; VHL = von Hippel-Lindau; RCC = renal cell carcinoma; CNS = central nervous system.',

  references: [
    'Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Kidney Cancer V.2.2024. © National Comprehensive Cancer Network, Inc. 2024. All rights reserved. Accessed January 5, 2024. To view the most recent and complete version of the guideline, go to NCCN.org.',
    'Referenced with permission from the NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®) for Central Nervous System Cancers V.1.2023. © National Comprehensive Cancer Network, Inc. 2023. All rights reserved. Accessed April 3, 2023. To view the most recent and complete version of the guideline, go to NCCN.org.',
  ],

  warrantyFootnote: [{copy: 'NCCN makes no warranties of any kind whatsoever regarding their content, use or application and disclaims any responsibility for their application or use in any way.'}],
};

export default guidelinesCopy;
