import styled from 'styled-components';
import { black } from '../../styles/shared/colors';

export const FootnoteContainer = styled.section`

`;

export const FootnoteItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: ${({ marginBottom }) => marginBottom ? '8px' : '0'};
  text-align: left;
  font-size: ${({ large }) => large ? '15px' : '13px'};
  line-height: 1.3;
  font-weight: 300;
  color: ${black};
`;

export const FootnoteRef = styled.sup`
  display: inline-block;
  flex: 0 0 7px;
  font-size: 10px;
  line-height: 1em;
  margin-left: -7px;
`;

export const FootnoteCopy = styled.div`
  display: inline-block;
  flex: 1 0 0;
`;