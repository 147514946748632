import * as React from 'react';

// Components
import PageSeo from '../../components/Seo/PageSeo';
import PageSection from '../../components/PageSection';
import IconCard from '../../components/IconCard';
import Footnotes from '../../components/Footnotes';
import * as s from '../../styles/pages/guidelines';
import Reference from '../../components/Reference';
import ButtonBgCallout from '../../components/ButtonBgCallout';
import PageTitle from '../../components/PageTitle';
import { TextBlock, CalloutBanner } from '../../components';
import styled from "styled-components";

// Content
import guidelinesCopy from '../../copy/guidelines';

const pageMeta = {
  title: 'Guidelines for WELIREG™ (belzutifan) | Von Hippel-Lindau Disease',
  keywords: 'welireg for vhl disease guidelines, belzutifan for vhl disease guidelines',
  description: 'Belzutifan (WELIREG) is included in certain NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®). Health care professionals can review these guidelines here.',
  schemaJsonLD: [
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/guidelines/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/guidelines/","@type":"MedicalEntity","guideline":"Belzutifan (WELIREG) is the only preferred systemic therapy option (category 2A) for patients with VHL disease–associated RCC not requiring immediate surgery.","recognizingAuthority":"NCCN® = National Comprehensive Cancer Network®"}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/guidelines/","@type":"MedicalEntity","guideline":"Belzutifan (WELIREG) is the only systemic therapy option identified as useful in certain circumstances (category 2A) for patients with VHL disease–associated CNS hemangioblastomas not requiring immediate surgery.","recognizingAuthority":"NCCN® = National Comprehensive Cancer Network®"}`
  ]
}

const StyledTextBlock = styled(TextBlock)`
  [class*='TextBlock__Container-'] h3 { 
    font-weight: 500;
  }
`;

const calloutBanner = {
  title: "Learn more about these attributes of WELIREG:",
  continualInvertedButtons: true,
  links: [
    {
      label: "Safety",
      url: "/von-hippel-lindau/adverse-reaction-profile/",
    },
    {
      label: "Efficacy",
      url: "/von-hippel-lindau/efficacy/",
    },
    {
      label: "Dosing",
      url: "/von-hippel-lindau/dosing/",
    }
  ]
}

const GuidelinesPage = () => {
  return (
    <>
      <PageSeo pageMeta={pageMeta} >
        <link rel="canonical" href="https://www.welireghcp.com/von-hippel-lindau/guidelines/" />
      </PageSeo>
      
      <PageTitle >
        { guidelinesCopy.pageTitle }
      </PageTitle>

      <PageSection btmFlat>

        <StyledTextBlock
          title={
            <>
            Belzutifan (WELIREG) is a recommended option by National Comprehensive Cancer Network® (NCCN®) across 2 VHL disease&ndash;associated tumor types<sup>1,2</sup>
            </>
            }
        />

        <s.GuideIconRow gap="20px" mobileGap="24px">
          {guidelinesCopy.iconCards.map((card, i) => (
            <IconCard
              key={i}
              title={ card.title }
              copy={ card.copy }
              alt={ card.alt }
            />
          ))}
        </s.GuideIconRow>
      </PageSection>

      <PageSection btmFlat>
        <Footnotes items={guidelinesCopy.footnotes}/>
      </PageSection>
      
      <PageSection>
        <Reference
            data={guidelinesCopy.references}
          />
      </PageSection>

      <CalloutBanner {...calloutBanner} />

    </>
  );
};

export default GuidelinesPage;
