import styled from 'styled-components';
import { H2 } from '../shared/typography';
import { respondTo } from '../shared/breakpoints';
import FlexRow from '../../components/FlexRow';
import * as colors from '../../styles/shared/colors';


export const GuideHeader = styled(H2)`
  margin-bottom: 24px;

  ${respondTo.sm`
    margin-bottom: 48px;
  `}
`;

export const GuideIconRow = styled(FlexRow)`


`;

export const GuideRowFull = styled.div`
  border: 1px solid ${colors.pebble};
  border-radius: 8px;
  padding: 24px;
  background-color: ${colors.white};
  display: inline-flex;
  width: 100%;
  h6 {
    color: ${colors.brandBlueLight};
  }
  ${respondTo.sm`
    margin-bottom: 0;
  `}
`;

export const FlashIcon = styled.div`
  padding-right: 30px;
`;

export const FlashWrap = styled.div`
  padding-right: 30px;
`;

export const ButtonDownload = styled.a`
  color: #FFFFFF;
  background-image: linear-gradient(to right,#D63227 0%,#D63227 50%,#5D4377 calc(50% + 0.1px),#5D4377 100%); 
  min-width: 120px;
  margin: 24px 0 0;
  padding: 0 0 0 25px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;
    border-radius: 50px;
    cursor: pointer;
    background-size: 201% 100%;
    background-origin: border-box;
    border: none;
    transition: background-position 0.2s ease-in-out,color 0.2s ease-in-out;
    align-content: flex-start;
    font-size: 1rem;
    line-height: 1.1em;
    font-weight: 500;
`;

export const DownloadArrowWrap = styled.div`
  width: 40px;
  height: 40px;
  background-color: #B52A21;
  border-radius: 0 50px 50px  0;
  margin: 0 0 0 25px;
  img {
    padding:14px 14px;
  }
`;